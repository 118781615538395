<template>
  <form id="frm_action" class="parent_page_detail order-progress--view">
  <div class="p-grid list_group_order">
    <div class="col-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <div class="row align-items-end justify-content-between pb-3 pr-3">
          <div class="col-lg-6 col-12 row">
            <div class="col-lg-6 col-12 px-0 pl-2">
                <label class="col-fixed txt-left px-2 m-0">Xem thống kê</label>
                <div class="col pl-2">
                  <Calendar
                      :disabled="loading"
                      @clear-click="headerOnFilter"
                      @date-select="headerOnFilter"
                      v-model="dateReport" placeholder="DD/MM/YYYY - DD/MM/YYYY" class="w-100" appendTo="body" :showButtonBar="true" selectionMode="range" dateFormat="dd/mm/yy" :showIcon="true"/>
                </div>
            </div>
            <div class="col-lg-6 col-12 px-0 pl-2">
                <label class="col-fixed txt-left px-2 p-0 m-0">Công ty</label>
                <div class="col pl-1">
                  <v-select :disabled="loading" v-on:input="process_change_company" title="Xem theo kho" id="select_company_id" :filterable="false" placeholder="Chọn" v-model="selCompany" :options="listCompany" label="code" @search="fetchOptionsCompany">
                      <template #selected-option="{ code, name }">
                        <em><b>{{code}}</b> - {{name}}</em>
                      </template>
                      <template #option="{ code, name }">
                        <em><b>{{code}}</b> - {{name}}</em>
                      </template>
                      <span slot="no-options" @click="$refs.select.open = false">
                          Không có dữ liệu
                      </span>
                  </v-select>
                  <input class="input_tmp_validator" id="company_id">
                </div>
            </div>
          </div>
          <div class="col-lg-6 col-12 p-0 d-flex justify-content-end">
            <Button :disabled="loading" v-if="this.role.export == true" label="Xuất excel" class="main-style-button p-2" @click="exportExcelFunc" style="padding: .75rem !important" />
          </div>
        </div>
        <!--{{listData}}-->
        <BaseDatatable
          :filterDefault="filters"
          :key="componentKey"
          :tableData="listData"
          :model.sync="dataModel"
          :options="options"
          :getFilterOptions="getFilterOptions"
          :loading.sync="loading"
          :whereKeyList="whereKeyList"
          :listDataService="data"
          @getFilters="getFilters"
          :totalRecords.sync="total"
          @open-parcel="showPopup"
        ></BaseDatatable>
          
          <Dialog
          :header="headerParcelPopup"
          :visible.sync="showParcelPopup"
          :contentStyle="{overflow: 'visible'}"
          :modal="true"
          :dismissableMask="true"
          class="dialog-parcel"
        >
          <div class="d-flex flex-direction-column align-items-center" v-if="parcelData.length > 0">
            <div class="col-12 row mb-3 p-0">
              <div class="col-8 px-0">
                <span class="bold">
                  Kiện hàng
                </span>
              </div>
              <div class="col-4 px-0">
                <span class="bold">
                  Số lượng
                </span>
              </div>
            </div>
            <div class="col-12 row mb-3 p-0" v-for="(item,index) in parcelData" :key="index">
              <div class="col-8 px-0">
                <div class="parcel_name_class">
                  {{item.parcel.name || '-'}}
                </div>
              </div>
              <div class="col-4 px-0 pl-2">
                <div class="parcel_name_class">
                  {{ $commonFuction.numberFormat(item.number, 0) }}
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="txt-center w-100">Chưa có kiện hàng</div>
          </div>
          
          <template #footer>
              <Button label="Đóng" class="main-style-button" icon="fa fa-check" v-if="parcelData.length > 0" @click="showParcelPopup = false"/>
          </template>
        </Dialog>

        <Dialog header="Thông báo" :visible.sync="popupWarning" id="warning_poins_errs">
          <span class="txt-center">
            Dữ liệu đang được xử lý. Chúng tôi sẽ gửi email cho bạn sau khi xuất xong dữ liệu.
          </span>
          <template
              #footer
              class="justify-content-center"
            >
            <Button label="Xác nhận" @click="popupWarning = false" icon="pi pi-check" class="" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
  </form>
</template>

<script>
import DataServices from '@/core/DataServices'
import BaseDatatable from './list'
// import gql from "graphql-tag";
import ApiRepository from "@/core/ApiRepository";
//import configJson from '../../config';
// import axios from 'axios'
// import Popup from './popup'
import AuthenticationApp from "@/core/AuthenticationApp";
export default {
  components: {
    BaseDatatable,
    // Popup
  },
  data() {
    return {
      popupWarning: false,
      filters: {},
      first: 0,
      rows: 20,
      total: 0,
      loading: false,
      list_map_code_order:{},
      name_process_import:'',
      value_process_import:0,
      display_progress_bar :false,
      files:[],
      dislay_button:1,
      data_popup_confirm_address:{
        display:false,
      },
      data_popup_import_faild:{
        display:false,
        text_err:"",
        link_err:"",
        /*list_pakage_data:[],
        list_parcel:[],
        obj_data:{
          combine_packages:false,
          code_combine_packages:'',
          id_combine_packages:''
        }*/
      },
      data_popup_update_pakage:{
        display:false,
        list_pakage_data:[],
        list_parcel:[],
        obj_data:{
          combine_packages:false,
          code_combine_packages:'',
          id_combine_packages:''
        }
      },
      order_id_pakage:null,
      list_select_item:[],
      list_order_select_item:[],
      filterOptions: {},
      options: [],
      listData: [],
      modelName: '',
      dataModel: null,
      title: '',
      componentKey: 0,
      editId: null,
      displayDialog: false,
      editDialogKey: 0,
      dialogMode: 'edit',
      deleteId: null,
      deleteName: null,
      showDeleteDialog: false,
      whereKeyList:{
        where_key: {
          /*accounts: {
            role: {_eq: "admin"},
            deleted: {_eq: false}
          },*/
          deleted: {_eq: false}
        }
      },
      page_transaction:null,
      role: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        export: false,
      },
      baseUrl:'',
      list_status_change:{},
      listCompanyAll: [],
      listCompany: [],
      selCompany: null,
      dateReport: null,
      data: [],
      showParcelPopup: false,
      headerParcelPopup: 'Tổng số lượng kiện ',
      parcelData: [],
      changeFilter: 0
    }

  },
  // mounted () {
  //   this.initParams();
  // },
  async created() {
    await this.initParams();
   // console.log("this.$CoreService.baseurl():",this.$CoreService.baseurl())
   // var envConfig = (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'production' || process.env.NODE_ENV == 'test') ? process.env.NODE_ENV:'development';
    //this.baseUrl = configJson[envConfig].base_url_api;

   /* this.modelName = this.$route.meta.model;
    this.dataModel = DataServices.getModel(this.modelName);
    this.title = this.dataModel.name
    this.$store.commit('setLoading', true);
    if(this.$route.params.reload) {
      window.location.reload();
    }*/
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ORDER_PROGRESS);
    if (arr_role.indexOf(this.$constants.ROLE.VIEW) < 0){
      this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
    }
    console.log("arr_role",arr_role)
    this.role.export = (arr_role.indexOf(this.$constants.ROLE.EXPORT) < 0) ? false:true;

    console.log("import",this.role.import)
    this.role.edit = (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0) ? false:true;
    this.role.delete = (arr_role.indexOf(this.$constants.ROLE.DELETE) < 0) ? false:true;

  },
  watch: {
    '$route': 'initParams',
    /*"dateReport"() {
      // this.loading = true;
        if (this.dateReport) {
          if (!this.$commonFuction.isEmpty(this.dateReport[0]) && !this.$commonFuction.isEmpty(this.dateReport[1])) {
              this.$set(this.filters, 'date_order_filter', {value: this.dateReport, type_input: 'DateRange'});
          }
        } else {
            this.$delete(this.filters, 'date_order_filter');
        }
      // setTimeout(() => {
      //   this.loading = false;
      // }, 200)
        if (!this.loading && !this.$commonFuction.isEmpty(this.dateReport[0]) && !this.$commonFuction.isEmpty(this.dateReport[1])) {
          this.getData();
        }
        return;
    },
    "selCompany"() {
        // this.loading = true;
        if (this.selCompany?.id) {
            this.$set(this.filters, 'company_id', {value: this.selCompany?.id, type_input: 'select'});
        } else {
            this.$delete(this.filters, 'company_id');
        }
        // setTimeout(() => {
        //   this.loading = false;
        // }, 200)
        if (!this.loading) {
          this.getData();
        }
        return;
    },*/
  },
  methods: {
    async process_change_company() {
      this.changeFilter = 0;
      if (this.selCompany?.id) {
        this.$set(this.filters, 'company_id', {value: this.selCompany?.id, type_input: 'select'});
        this.changeFilter++;
      } else {
        this.$delete(this.filters, 'company_id');
        this.changeFilter++;
      }
      if (this.changeFilter > 0) {
        this.getData();
      }
    },
    headerOnFilter() {
      this.changeFilter = 0;
      this.change_filter();
      if (this.changeFilter > 0) {
        this.getData();
      }
    },
    async change_filter() {
      if (this.dateReport) {
        if (!this.$commonFuction.isEmpty(this.dateReport) && !this.$commonFuction.isEmpty(this.dateReport[0]) && !this.$commonFuction.isEmpty(this.dateReport[1])) {
          this.$set(this.filters, 'date_order_filter', {value: this.dateReport, type_input: 'DateRange'});
          this.changeFilter++;
        }
      } else {
        this.$delete(this.filters, 'date_order_filter');
        this.changeFilter++;
      }
    },
    async showPopup(data) {
      this.headerParcelPopup = `Tổng số lượng kiện ${data.code}`;
      this.parcelData = data?.order_group_parcel?.order_parcels || [];
      this.showParcelPopup = true;
    },
    async getListCompany() {
      var whereAttach ={}
      var listDisplayCompany = AuthenticationApp.getListCompany();
      if (listDisplayCompany != null) {
        //order_shipping(where: {order_shipping_items: {order: {company_id: {_in: ""}}}})
        whereAttach.id =  {_in: listDisplayCompany};
      }
      var list =await this.$CoreService.getCompanyFilter(whereAttach);
        var list_Region = [];
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_Region.push({id : e.id, name: e.name, code: e.code});
        }
        this.listCompany = list_Region;
        this.listCompanyAll = list_Region;
      if (list_Region.length > 0){
       // console.log("list_company[0]list_company[0]:",list_company[0])
        this.$set(this, 'selCompany', list_Region[0]);
      }
    },
    
    async fetchOptionsCompany(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          graft_code_name_company:{_iregex: this.$commonFuction.search(search)},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('company', {"fields" : `id code name`}),
          variables: {
            where_key: where_key,
            orderBy:[{code : "asc" }]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_Region = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_Region.push({id : e.id, name: e.name, code: e.code});
        }
        this.listCompany = list_Region;
      }else{
        // cosnole.log("dasdasd")
        this.listCompany = this.listCompanyAll
      }
    },
    async initParams() {
      this.$store.commit('setLoading', true);
      this.modelName = this.$route.meta.model;
      this.dataModel = DataServices.getModel(this.modelName);
      this.title = this.dataModel.label;
      this.$set(this, 'dateReport', [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()]);
      await this.getListCompany();
      await this.change_filter();
      this.callbackOptions();
      this.process_change_company();
    },
    onChange() {
      this.$store.commit('setLoading', true);
      this.$apollo.queries.listData.refresh()
    },
    getFilterOptions(name) {
      var field = this.dataModel.getField(name)
      var result = []
      if (field.type == 'Selection') {
        //result = field.options;
        if (field.options ) result = field.options;
        else if(field.models && this.options[name]) result = this.options[name];
      } else {
        result = this.filterOptions[name] != null ? [...this.filterOptions[name]] : []
      }
      return result;
    },
    getRowActions() {
      var actions = {}
      // var that = this;
      /*this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ADMIN,this.$constants.ROLE.ADD).then( (res)  => {
       if (res){
         actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`};
       }
      })*/
      /*if (this.role.add) {
        actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`};
      }*/
      //if ((await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ADMIN,this.$constants.ROLE.ADD))){

      // actions.header = { name: 'add', icon: 'plus'};
      // actions.rows = function (rowData) {
      //   var buttons = {}
      //   // buttons.view = { name: 'view', icon: 'eye', key: 'id', path: `list-${that.modelName}/view/`};
      //  /* if (that.role.edit) {
      //     buttons.edit = {name: 'edit', icon: 'pencil', key: 'id', path: `list-${that.modelName}/edit/`};
      //   }*/
      //   // buttons.edit = { name: 'edit', icon: 'pencil', key: 'id', path: `${that.modelName}/edit/`}
      //  /* if (that.role.delete) {
      //     buttons.delete = {name: 'delete', icon: 'trash', key: 'id'}
      //   }*/

      //   if (that.dataModel.actions) {
      //     var modelaction = that.dataModel.actions(rowData)
      //     Object.assign(buttons, modelaction);
      //   }
      //   return buttons
      // }
      return actions
    },
    async callbackOptions() {
      var fields = this.dataModel.getDisplayFields();
      for (let index = 0; index < fields.length; index++) {
        const element = fields[index];
        if (element.models !== undefined && element.models !== null) {
          var optionsData = [];
          var attributes = [];
          attributes.push(element.models.key.key);
          attributes.push(element.models.display_key);
          //console.log("attributesattributesattributes:",attributes)
          //var where_status = {}
          /*if( element['name']== "rel_status"){
            where_status =  {
              type: {'_eq': 'ORDER'}
            }
          }*/
          var where_ = {}
          if( element['name']== "name_group_filter"){
            where_ =  {
              active: {_eq: true},
              deleted: {_eq: false},
            }
            element.models.tableName = 'group'
          }else if(
              element['name']== "once_reason"
              || element['name']== "second_reason"
              || element['name']== "third_reason"
          ){
            where_ =  {
              deleted : {_eq: false},
              //type : {_eq: 2},
            }
            element.models.tableName = 'reason'
          }
          var {data} = await this.$apollo.query({
            query: DataServices.getList(element.models.tableName, {"fields" : attributes.join(',')}),
            variables: {where_key : where_}
          })
          if (data) {
            var result = data[Object.keys(data)];
            if (result) {
              for (let index = 0; index < result.length; index++) {
                const ele = result[index];
                optionsData.push({value: ele[attributes[0]], label: ele[attributes[1]]})
              }
            }
          }
          this.options[element.key] = optionsData;
        }

      }
      this.componentKey++;
    },
    async onActionClicked(payload) {
      var action = payload.action
      var data = null
      if (payload.data) {
        data = payload.data
      }
      var pageTransaction = (new Date()).getTime();
      switch (action.name) {
        case 'view':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path + data.data[action.key] })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'edit':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            console.log("payload.lazyParams:111"+pageTransaction,payload.lazyParams);
            localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
            this.$router.push({ path: action.path + data.data[action.key],query: { page_transaction: pageTransaction.toString() }  })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'delete':
          localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
          this.page_transaction = pageTransaction;
          this.deleteId = data.data[action.key]
          this.deleteName = data.data.name
          this.showDeleteDialog = true
          break
        case 'add':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path })
          } else  {
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        // case 'custom':
        //   await action.handler(data.data, this)
        //   this.componentKey++
        //   break
      }
    },
    async getFilters(lazyParams) {
      const {filters, first, rows} = lazyParams;
      this.filters.filters = {...this.filters, ...filters};
      this.first = first;
      this.rows = rows;
      if (!this.loading) {
        return this.getData();
      }
      return;
    },
    async getData() {
      this.loading = true;
      var headers = {
        "Authorization": "Bearer "+( await this.$AuthenticationApp.getToken()),
      }
      
      var filters = {};
      if (this.filters?.filters) {
        for (const [key, value] of Object.entries(this.filters?.filters)) {
          filters[key] = value.value;
        }
      } else {
        for (const [key, value] of Object.entries(this.filters)) {
          if (key != 'filters') {
            filters[key] = value.value;
          }
        }
      }
      
      ApiRepository.post("_api/common/get_list_order_progress", {filters: filters, first: this.first, rows: this.rows}, {headers}).then((res) => {
        const {data} = res;
        this.total = data?.data?.data?.count || 0;
        this.data = data?.data?.data?.list || [];
        this.data = this.data.sort((a, b) => a.stt - b.stt);
        this.loading = false;
      }).catch(async error => {
          console.log('error', error);
          this.$toast.add({
              severity: 'error',
              summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
              detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
              life: 3000
          });
          this.loading = false;
      });
    },
    async exportExcelFunc() {
    //if (this?.dateReport && this.dateReport.length > 1) {
      //var datediff = (this.dateReport[1].getTime() - this.dateReport[0].getTime()) / (1000 * 60 * 60 * 24);
      //if (datediff > 35) {
        //alert("Vui lòng chọn khoảng thời gian < 35 ngày");
        //return;
      //}
    //} else {
      //alert("Vui lòng chọn khoảng thời gian < 35 ngày");
      //return;
    //}
    this.loading = true;
      var headers = {
        "Authorization": "Bearer "+( await this.$AuthenticationApp.getToken()),
      }
      
      var filters = {};
      if (this.filters?.filters) {
        for (const [key, value] of Object.entries(this.filters?.filters)) {
          filters[key] = value.value;
        }
      } else {
        for (const [key, value] of Object.entries(this.filters)) {
          if (key != 'filters') {
            filters[key] = value.value;
          }
        }
      }
      
      ApiRepository.post("_api/common/get_excel_order_progress", {filters: filters}, {headers}).then((res) => {
        console.log("resresresresresresresresresres", res);
        this.loading = false;
        if (res?.data?.data?.linkExcel) {
          window.open(res?.data?.data?.linkExcel);
        }
        this.popupWarning = true;
      }).catch(async error => {
          console.log('error', error);
          this.$toast.add({
              severity: 'error',
              summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
              detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
              life: 3000
          });
          this.loading = false;
      });
    }
  },
  /*apollo: {
    listData: {
      query() {
        this.modelName = this.$route.meta.model;
        console.log('8*************************',this.modelName);
        return DataServices.getList(this.modelName);
      },
      variables () {
        // Use vue reactive properties here
        return this.whereKeyList;
      },
      update(data) {
        console.log('this.dataModel.tableName',data);
        var listData = data[this.dataModel.tableName]
        this.title = this.dataModel.label + ' '
        this.dataModel.processData(listData)
        this.$store.commit('setLoading', false);
        this.componentKey += 1;
        console.log('data[this.dataModel.tableName]',data[this.dataModel.tableName]);
        return data[this.dataModel.tableName]
      },
      fetchPolicy: 'network-only',
      error() {
        this.$store.commit('setLoading', false);
      },
      skip() {
        return !this.modelName
      }
    }
  }*/

}
</script>
<style lang="css">
.p-calendar .p-datepicker { width: 280px; padding: 0px; padding-top: 5px;}
.p-calendar .p-datepicker td, .p-calendar .p-datepicker td a {padding: 0px !important;}
.p-calendar .p-datepicker td span {padding: 0px !important; width: 30px !important; height: 30px !important;}
.p-datepicker .p-datepicker-header{padding: 5px !important;}
/*.style-button-common{
  background: #C40380 0% 0% no-repeat padding-box;
  border-radius: 3px;
  color: #FFFFFF;
}*/
</style>
<style lang="scss">
.list_group_order {
  .group-button-list-top {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 16px;
    padding-right: 0px;

    .style-button-common {
      margin-left: 8px;
     /* margin-right: 8px;*/
      margin-top: 8px;
    }

    .style-button-common:last-of-type {
      margin-right: 0px;
    }
  }
.group-button-list-top-custom{
  display: inline;
  text-align: right;
}
  .style-popup-update-pakage,style-popup-confirm-address
  {
    width: 600px;
  }
  .style-popup-update-pakage{
    .style_inpuit_order{
      width: 100%;
    }
    .pakage_popup_dropdown{
      width: 100%;
    }
    .item-right-popup{
      width: 212px !important;
    }
    .item-left-popup{
      width:calc(100% - 212px) !important;
    }
    .style_group_pakage{
      position: relative;
      .popup_qty_pakage{
        width: 166px;
      }
      .style_icon_del_pakage{
        cursor: pointer;
        position: absolute;
        width: 38px;
        height: 32px;
        right: 0px;
        margin-top: auto;
        margin-bottom: auto;
        top: 0px;
        bottom: 0px;
      }
    }
  }
  .import-order-btn{
    position: relative;
    display: inline;
    .input-file-order{
      position: absolute;
    }
  }
  
}

.dialog-parcel {
  width: 600px;
  .parcel_name_class {
    width: 100%;
    border: 1px solid #D0D4D9;
    border-radius: 5px;
    padding: 10px;
  }
}
.flex-direction-column {
  flex-direction: column;
}
.txt-center {
  text-align:center !important;
}
.p-dropdown-panel .p-dropdown-items{
  max-width: 300px;
  overflow: scroll;
}
</style>